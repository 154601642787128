import jwt_decode from "jwt-decode";
import store from "../../store/index";
import axios from "axios";
export default {
  data() {
    return {
      loadingForm: false,
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      showValidation: false,
      sectionARows: [
        {
          evaluationArea: "",
          project: "",
          scoreByEmployee: null,
          leadScore: null,
          leadComments: "",
        },
      ],
      validationErrors: [],
      validationErrorsB: [],
      validationErrorsD: [],
      visibleOn: false,
      templatePassword : "",
    
      validationErrorsForSectionB: [],
      validationErrorsE: {
        achievementsFailures: false,
        improvementNeeded: false,
        goalsForNextYear: false,
        employeeResponse: false
      },
      validationErrorsC: {},
      sectionBRows: [
        {
          skillTechnology: "",
          project: "",
          evidence: "",
          employeeScore: null,
          leadScore: "",
          leadComments: "",
        },
      ],
      signedDate: "",

      reason: "",
      popupErrors: {},
      sectionDRows: [{ skill: "", evaluationArea: "", explanation: "" }],
      sectionERows: {
        teamLeadComments: "",
        achievementsFailures: "",
        improvementNeeded: "",
        goalsForNextYear: "",
        discussionWithLead: {
          employeeResponse: '',
          leadResponse: '',
        },
      },
      // totalStatus: {
      //   emp: {
      //     status: null,
      //   },
      // },
      sectionCData: [
        {
          tableDataHeader: "Header 1",
          contents: ["Question 1", "Question 2"],
        },
        {
          tableDataHeader: "Header 2",
          contents: ["Question 3"],
        },
      ],
      sectionCResponses: [
        {
          responses: [
            { employeeScore: "", leadScore: "" },
            { employeeScore: "", leadScore: "" },
          ],
        },
        {
          responses: [{ employeeScore: "", leadScore: "" }],
        },
      ],
      userId: "",
      assessmentData: {},

      assessmentTemplate: null,

      showpopup: false,
      inputTypes: "password",
      loadingSaveBlock: false,
      errorMsg: null,
    };
  },

  mounted() {
    this.idtoken = store.getters.getToken;
    try {
      const decodedToken = jwt_decode(this.idtoken);
      this.userId = decodedToken.user_id;
      console.log("Editing assessment with ID:", this.userId);
      this.getAssessmentTemplate();
      this.getAssessmentDetailsById();
    } catch (error) {
      console.error("Error fetching assessment data:", error);
    }
  },
  methods: {
    onClosed() {
      this.$router.push({ name: "Assessment" });
    },
    async showPopupvalid(value) {
      this.inputType = value;
      if (this.inputType == "Emp_Submit") {
       
        await this.getReasons();
        this.showpopup = true;
      }

      // this.getLocation()

      // this.saveData();
    },

    passwordVisible() {
      this.visibleOn = !this.visibleOn
      if (this.visibleOn == true) {
        this.inputTypes = "text"
        console.log("ffhfhghjgjhgj")
      }
      else this.inputTypes = "password"
    },

    addNewRow() {
      this.sectionARows.push({
        evaluationArea: "",
        project: "",
        scoreByEmployee: "",
        leadScore: "",
        leadComments: "",
      });
    },
    validateRows() {
      let isValid = true;
      this.validationErrors = [];
      this.sectionARows.forEach((row, index) => {
        const errors = {};
        if (!row.evaluationArea || !row.project || !row.scoreByEmployee) {
          isValid = false;
          errors.evaluationArea = !row.evaluationArea;
          errors.project = !row.project;
          errors.scoreByEmployee = !row.scoreByEmployee;
        }
        this.validationErrors.push(errors);
      });
      if (!isValid) {
        this.closePopup();
        // window.alert("Please fill in all mandatory fieldsssssssssssss.");

        const firstInvalidIndex = this.validationErrors.findIndex(
          (error) =>
            error.evaluationArea || error.project || error.scoreByEmployee
        );
        if (firstInvalidIndex !== -1) {
          setTimeout(() => {
            const invalidElement = document.querySelector(
              `#evaluationArea-${firstInvalidIndex}`
            );
            if (invalidElement) {
              invalidElement.focus();
            }
          }, 0);
        }
      }
      return isValid;
    },
    handleInput(index, field) {
      this.clearValidationError(index, field);
      this.clearValidationErrorB(index, field);
      if (field === "scoreByEmployee") {
        this.validateScore(index, field);
      }
      if (field === "employeeScore") {
        this.validateScore(index, field);
      }
    },
    validateScore(index, field) {
      // Validation for section A
      if (this.sectionARows && this.sectionARows[index] && field in this.sectionARows[index]) {
        let value = this.sectionARows[index][field];
        value = value === "" ? "" : Number(value); 
        if (value === "" || isNaN(value) || value < 1 || value > 10) {
          this.sectionARows[index][field] = value === "" ? "" : Math.max(1, Math.min(value, 10));
        }
      } else if (field === "scoreByEmployee" || field === "employeeScore") {
        console.error(`Invalid index or field for sectionARows: index ${index}, field ${field}`);
      }
    
      // Validation for section B (only if the index and field are valid)
      if (this.sectionBRows && this.sectionBRows[index] && field in this.sectionBRows[index]) {
        let valueB = this.sectionBRows[index][field];
        valueB = valueB === "" ? "" : Number(valueB);
        if (valueB === "" || isNaN(valueB) || valueB < 1 || valueB > 10) {
          this.sectionBRows[index][field] = valueB === "" ? "" : Math.max(1, Math.min(valueB, 10));
        }
      } else if (field === "scoreByEmployee" || field === "employeeScore") {
        console.error(`Invalid index or field for sectionBRows: index ${index}, field ${field}`);
      }
    },
    
    
    validateSectionB() {
      let isValid = true;
      this.validationErrorsB = [];

      this.sectionBRows.forEach((row, index) => {
        const errors = {};
        if (
          !row.skillTechnology ||
          !row.project ||
          !row.evidence ||
          !row.employeeScore
        ) {
          isValid = false;
          errors.skillTechnology = !row.skillTechnology;
          errors.project = !row.project;
          errors.employeeScore = !row.employeeScore;
          errors.evidence = !row.evidence;
        }
        this.validationErrorsB.push(errors);
      });
      if (!isValid) {
        this.closePopup();
        const firstInvalidIndex = this.validationErrorsB.findIndex(
          (error) =>
            error.skillTechnology ||
            error.project ||
            error.evidence ||
            error.employeeScore
        );
        if (firstInvalidIndex !== -1) {
          setTimeout(() => {
            const invalidElement = document.querySelector(
              `#skillTechnology-${firstInvalidIndex}`
            ); // Adjust based on your actual input id
            if (invalidElement) {
              invalidElement.focus();
            }
          }, 0);
        }
      }
      return isValid;
    },
    validateSectionD() {
      let isValid = true;
      this.validationErrorsD = [];

      this.sectionDRows.forEach((row, index) => {
        const errors = {};

        // Check each field in the row
        if (!row.skill) {
          errors.skill = true;
          isValid = false;
        }
        if (!row.evaluationArea) {
          errors.evaluationArea = true;
          isValid = false;
        }
        if (!row.explanation) {
          errors.explanation = true;
          isValid = false;
        }

        this.validationErrorsD.push(errors);
      });

      if (!isValid) {
        this.closePopup();

        const firstInvalidIndex = this.validationErrorsD.findIndex(
          (error) => error.skill || error.evaluationArea || error.explanation
        );
        if (firstInvalidIndex !== -1) {
          setTimeout(() => {
            const invalidElement = document.querySelector(
              `#skill-${firstInvalidIndex}`
            );
            if (invalidElement) {
              invalidElement.focus();
            }
          }, 0);
        }
      }

      return isValid;
    },

    validateSectionC() {
      let isValid = true;
      this.validationErrorsC = {};
      let sectionCPosition = 0; // Adjust this to the actual position of Section C on the page
    
      this.sectionCResponses.forEach((question, index) => {
        question.responses.forEach((response, contentIndex) => {
          if (!response.employeeScore) {
            isValid = false;
            this.validationErrorsC[`employeeScore_${index}_${contentIndex}`] =
              "Please select an option";
          }
        });
      });
    
      if (!isValid) {
        this.closePopup();
        alert("Please fill in all mandatory fields.");
        window.scroll(0, 600); 
      }
    
      return isValid;
    },
    
    clearValidationErrorC(index, contentIndex, field) {
      const errorKey = `${field}_${index}_${contentIndex}`;
      if (this.validationErrorsC[errorKey]) {
        delete this.validationErrorsC[errorKey];
      }
    },

    validateSectionE() {
      let isValid = true;
      this.validationErrorsE = {};
    
     
      // if (!this.sectionERows.discussionWithLead.employeeResponse) {
      //   isValid = false;
      //   this.validationErrorsE.employeeResponse = true;
      // }
    
    
      if (!this.sectionERows.achievementsFailures) {
        isValid = false;
        this.validationErrorsE.achievementsFailures = true;
      }
      if (!this.sectionERows.improvementNeeded) {
        isValid = false;
        this.validationErrorsE.improvementNeeded = true;
      }
      if (!this.sectionERows.goalsForNextYear) {
        isValid = false;
        this.validationErrorsE.goalsForNextYear = true;
      }
    
      if (!isValid) {
      
        this.scrollToSectionE();
    
        alert("Please fill in all mandatory fields.");
        this.closePopup(); 
      }
    
      return isValid;
    },
    scrollToSectionE() {
    
      this.$refs.sectionE.scrollIntoView({
        behavior: 'smooth', 
        block: 'start'     
      });
    },

    validatePopupFields() {
      let isValid = true;
      this.popupErrors = {};
      if (!this.emailId) {
        isValid = false;
        this.popupErrors.emailId = "Email ID is required.";
      }
      if (!this.templatePassword) {
        isValid = false;
        this.popupErrors.templatePassword = "Password is required.";
      }
      if (!this.reasons) {
        isValid = false;
        this.popupErrors.reasons = "Reason is required.";
      }
      if (this.reasons === "Others" && !this.otherReason) {
        isValid = false;
        this.popupErrors.otherReason = "Other reason is required.";
      }
      return isValid;
    },

    async getReasons() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getallreason`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.reasonList = res.data;
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    addNewRowB() {
      this.sectionBRows.push({
        skill: "",
        project: "",
        evidence: "",
        scoreByEmployee: "",
        leadScore: "",
        leadComments: "",
      });
    },
    addNewRowD() {
      this.sectionDRows.push({
        Evaluation: "",
        Explanation: "",
      });
    },
    deleteRow(index) {
      this.sectionARows.splice(index, 1);
    },
    deleteRowB(index) {
      this.sectionBRows.splice(index, 1);
    },
    deleteRowD(index) {
      this.sectionDRows.splice(index, 1);
    },
    async getAssessmentTemplate() {
      try {
        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmettemplate?Id=a850b5bf-5532-4d6e-9dd8-5adc1987bf20`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        this.sectionCData = response.data.tableList.find(
          (item) => item.tableHeader === "Performance Review Questions"
        ).tableData;

        this.employeeResponse = this.sectionCData.map((mainQuestion) => {
          return mainQuestion.contents.map(() => "");
        });
        this.teamLeadResponse = this.employeeResponse.map((mainQuestion) => {
          return mainQuestion.map(() => "");
        });
      } catch (error) {
        console.error("Error fetching assessment template:", error);
      }
    },
    toggleTableHeight() {
      const tableContainer = document.querySelector(".table-container");
      if (
        tableContainer.style.maxHeight === "none" ||
        !tableContainer.style.maxHeight
      ) {
        tableContainer.style.maxHeight = "300px";
      } else {
        tableContainer.style.maxHeight = "none";
      }
    },
    closePopup() {
      this.emailId = "";
      this.templatePassword = "";
      this.reasons = "";
      this.otherReason = "";
      this.popupErrors = {};
      this.showpopup = false;
    },
    clearValidationError(index, field) {
      if (this.validationErrors[index]) {
        this.validationErrors[index][field] = false;
        if (
          Object.values(this.validationErrors[index]).every((error) => !error)
        ) {
          this.validationErrors.splice(index, 1, {});
        }
      }
    },

    clearValidationErrorB(index, field) {
      if (this.validationErrorsB[index]) {
        this.validationErrorsB[index][field] = false;
        if (
          Object.values(this.validationErrorsB[index]).every((error) => !error)
        ) {
          this.validationErrorsB.splice(index, 1, {});
        }
      }
    },
    clearValidationErrorD(index, field) {
      if (this.validationErrorsD[index]) {
        this.validationErrorsD[index][field] = false;
        if (
          Object.values(this.validationErrorsD[index]).every((error) => !error)
        ) {
          this.validationErrorsD.splice(index, 1, {});
        }
      }
    },
    clearValidationErrorE(fieldName) {
      this.validationErrorsE[fieldName] = false;
    },
    clearPopupFieldError(field) {
      if (this.popupErrors[field]) {
        delete this.popupErrors[field];
      }
    },
    validateAllfields() {
      const sectionDValid = this.validateSectionD();
      const sectionBValid = this.validateSectionB();
      const rowsValid = this.validateRows();

      return sectionDValid && sectionBValid && rowsValid;
    },

    async saveData(input) {
      const isValid = this.validateAllfields();

      if (!isValid) {
        console.error("Validation failed. Data not saved.");
        return;
      }

      const currentDate = new Date();
      console.log("Assessment Data:", this.employeeName);
      let requestData = {};

      if (input === "Emp_Submit") {
        this.validateSectionC();
        this.validateSectionE();
        if (!this.validatePopupFields()) {
          return;
        }
        requestData = {
          InputType: input,
          Email: this.emailId,
          SignerPassword: this.templatePassword,
          Reason: this.reasons,
          documentType: "Assessment",

          sectionA1: {
            rolesResponsibilities: "Roles and responsibilities",
            evaluationAreas: this.sectionARows.map((row) => ({
              evaluation_Area: row.evaluationArea,
              project: row.project,
              employeeScore:
                row.scoreByEmployee !== undefined &&
                row.scoreByEmployee !== null
                  ? row.scoreByEmployee.toString()
                  : "",
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionB1: {
            skillsTechnologies: this.sectionBRows.map((row) => ({
              skillTechnology: row.skillTechnology,
              project: row.project,
              evidence: row.evidence,
              employeeScore:
                row.employeeScore !== undefined && row.employeeScore !== null
                  ? row.employeeScore.toString()
                  : "",
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionC1: {
            performanceReviewQuestions: {
              mainQuestions: this.sectionCResponses.map((mainQuestion) => ({
                title: mainQuestion.title,
                characteristics: mainQuestion.responses.map((response) => ({
                  name: response.name,
                  employeeScore: response.employeeScore,
                  leadScore: response.leadScore,
                })),
              })),
            },
          },

          sectionD1: this.sectionDRows.map((row) => ({
            skill: row.skill,
            evaluationArea: row.evaluationArea,

            explanation: row.explanation,
          })),
          sectionE1: {
            teamLeadComments: this.sectionERows.teamLeadComments,
            achievementsFailures: this.sectionERows.achievementsFailures,
            improvementNeeded: this.sectionERows.improvementNeeded,
            goalsForNextYear: this.sectionERows.goalsForNextYear,
            discussionWithLead: {
              employeeResponse:
                this.sectionERows.discussionWithLead.employeeResponse,
              leadResponse: this.sectionERows.discussionWithLead.leadResponse,
            },
            assessmentCompletedSignOff: {
              employee: this.sectionERows.signOffEmployee,
              lead: this.sectionERows.signOffLead,
              hr: this.sectionERows.signOffHR,
            },
          },
          totalStatus: {
            emp: {
              signreferenceId: "",
              signedBy: this.employeeName,
              name: this.employeeName,
              signeddate: currentDate.toISOString(),
              empId: this.empName,
              reason: this.reasons,
              location: "India",
              status: "Completed",
            },
            lead: {},
            SecondaryLead:{}
          },
        };
      } else {
        requestData = {
          InputType: input,
          Email: this.emailId,
          SignerPassword: this.templatePassword,
          Reason: this.reasons,
          documentType: "Assessment",

          sectionA1: {
            rolesResponsibilities: "Roles and responsibilities",
            evaluationAreas: this.sectionARows.map((row) => ({
              evaluation_Area: row.evaluationArea,
              project: row.project,
              employeeScore: row.scoreByEmployee.toString(),
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionB1: {
            skillsTechnologies: this.sectionBRows.map((row) => ({
              skillTechnology: row.skillTechnology,
              project: row.project,
              evidence: row.evidence,
              employeeScore:
                row.employeeScore !== undefined && row.employeeScore !== null
                  ? row.employeeScore.toString()
                  : "",
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionC1: {
            performanceReviewQuestions: {
              mainQuestions: this.sectionCResponses.map((mainQuestion) => ({
                title: mainQuestion.title,
                characteristics: mainQuestion.responses.map((response) => ({
                  name: response.name,
                  employeeScore: response.employeeScore,
                  leadScore: response.leadScore,
                })),
              })),
            },
          },

          sectionD1: this.sectionDRows.map((row) => ({
            skill: row.skill,
            evaluationArea: row.evaluationArea,

            explanation: row.explanation,
          })),
          sectionE1: {
            teamLeadComments: this.sectionERows.teamLeadComments,
            achievementsFailures: this.sectionERows.achievementsFailures,
            improvementNeeded: this.sectionERows.improvementNeeded,
            goalsForNextYear: this.sectionERows.goalsForNextYear,
            discussionWithLead: {
              employeeResponse:"No",
                // this.sectionERows.discussionWithLead.employeeResponse,
              leadResponse: this.sectionERows.discussionWithLead.leadResponse,
            },
            assessmentCompletedSignOff: {
              employee: this.sectionERows.signOffEmployee,
              lead: this.sectionERows.signOffLead,
              hr: this.sectionERows.signOffHR,
            },
          },
          totalStatus: {
            emp: {},
            lead: {},
            SecondaryLead:{},
          },
        };
      }

      try {
        const currentUrl = window.location.href;

        const urlParts = currentUrl.split("/");

        const assessmentId = urlParts[urlParts.length - 1];
        console.log("Assessment ID:", this.sectionCResponses.employeeScore);
        const response = await axios.put(
          `${this.baseurl}document/projectdevdocumenttemplate/updateassesmentemp?id=${assessmentId}`,
          // `https://localhost:5001/projectdevdocumenttemplate/updateassesmentemp?id=${assessmentId}`,

          requestData,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Data saved successfully:", response.data);
        window.alert("Assessment saved successfully!");
     
        this.$router.push({ name: "Assessment" });
        
       
      
      } catch (error) {
        console.error("Error saving data:", error);
        window.alert("Invalid email or password");
      }
    },
    async getAssessmentDetailsById() {
      try {
        const currentUrl = window.location.href;
        const urlParts = currentUrl.split("/");
        const assessmentId = urlParts[urlParts.length - 1];
        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmentbyid?Id=${assessmentId}`,
// `https://localhost:5001/projectdevdocumenttemplate/getasesmentbyid?Id=${assessmentId}`,

          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          const assessmentData = response.data;
          console.log("Editing", assessmentData);

          if (
            this.userId === assessmentData.assessment.empdetailsTable.empName
          ) {
            this.disableCertainParts = true;
          } else {
            this.disableCertainParts = false;
          }
          if (assessmentData.assessment.sectionA1) {
            this.sectionARows =
              assessmentData.assessment.sectionA1.evaluationAreas.map(
                (area) => ({
                  evaluationArea: area.evaluation_Area,
                  project: area.project,
                  scoreByEmployee: area.employeeScore,
                  leadScore: area.leadScore,
                  leadComments: area.leadComments,
                })
              );
            console.log("Editinggggggggg", this.sectionARows);
          }
          if (assessmentData.assessment.sectionB1) {
            this.sectionBRows =
              assessmentData.assessment.sectionB1.skillsTechnologies.map(
                (skill) => ({
                  skillTechnology: skill.skillTechnology,
                  project: skill.project,
                  evidence: skill.evidence,
                  employeeScore: skill.employeeScore,
                  leadScore: skill.leadScore,
                  leadComments: skill.leadComments,
                })
              );
          }
          if (assessmentData.assessment.sectionD1) {
            this.sectionDRows = assessmentData.assessment.sectionD1;
          }
          if (assessmentData.assessment.sectionE1) {
            this.sectionERows = assessmentData.assessment.sectionE1;
          }
          if (
            assessmentData &&
            assessmentData.assessment &&
            assessmentData.assessment.sectionC1
          ) {
            const mainQuestions =
              assessmentData.assessment.sectionC1.performanceReviewQuestions
                .mainQuestions;

            const sectionCResponses = [];

            mainQuestions.forEach((mainQuestion) => {
              const title = mainQuestion.title;
              const characteristics = mainQuestion.characteristics;

              const questionResponses = [];

              characteristics.forEach((characteristic) => {
                const name = characteristic.name;
                const employeeScore = characteristic.employeeScore;
                const leadScore = characteristic.leadScore;

                questionResponses.push({
                  name: name,
                  employeeScore: employeeScore,
                  leadScore: leadScore,
                });
              });

              sectionCResponses.push({
                title: title,
                responses: questionResponses,
              });
            });

            this.sectionCResponses = sectionCResponses;
          } else {
            this.sectionCResponses = this.sectionCData.map((mainQuestion) => ({
              title: mainQuestion.tableDataHeader,
              responses: mainQuestion.contents.map((content) => ({
                name: content,
                employeeScore: "",
                leadScore: "",
              })),
            }));
          }
          if (assessmentData.assessment.totalStatus.emp.status == "Completed") {
            this.disableSaveAndSignButton = true;
            this.disableSaveButton = true;
          }

          if (assessmentData.employeeName) {
            this.employeeName = assessmentData.employeeName;
            this.teamLeadName = assessmentData.teamLeadName;
            this.empName = assessmentData.empdetailsTable.empName;
          }
        } else {
          throw new Error("Failed to fetch assessment details");
        }
      } catch (error) {
        console.error("Error fetching assessment details:", error);
      }
    },
  },
};
